import type {
    ArchizeenTileProps,
    ObjectShorthandValue,
} from '@archipro-design/aria';
import { ArchizeenTile } from '@archipro-design/aria';
import { useRef } from 'react';
import type { LogImpressionProps } from '@archipro-website/tracker';
import { useLogImpression } from '@archipro-website/tracker';
import { useHandleFollow } from '~/modules-rocco/design-board/hook/use-handle-follow';

export type TileProps = ObjectShorthandValue<ArchizeenTileProps> & {
    impression?: LogImpressionProps;
    articleId?: number;
};

const ArchizeenImpressionTile = (props: TileProps) => {
    const { impression, professionalID: _, articleId, ...rest } = props;
    const ref = useRef<HTMLAnchorElement | null>(null);
    const { handleFollow, isInDesignBoard } = useHandleFollow();
    useLogImpression({ data: impression, ref });
    return (
        <ArchizeenTile
            ref={ref}
            {...rest}
            onFavourite={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (!articleId) return;
                handleFollow({ id: articleId }, 'ArticlePin');
            }}
            isFavourited={
                !!(articleId && isInDesignBoard(articleId, 'ArticlePin'))
            }
        />
    );
};

export default ArchizeenImpressionTile;
